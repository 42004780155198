  <template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-text>source Data</v-card-text>
          <v-card-subtitle>
            <v-file-input
              accept=".csv,*.txt"
              label="source"
              @change="onSourceChange"
            ></v-file-input
          ></v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">LBKEY ({{ source_data.length }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in source_slice" :key="item.name">
                    <td>{{ item.lbkey }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-text>New Data</v-card-text>
          <v-card-subtitle>
            <v-file-input
              accept=".csv,*.txt"
              label="New"
              @change="onDestFileChange"
            ></v-file-input
          ></v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">LBKEY ({{ dest_data.length }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dest_slice" :key="item.name">
                    <td>{{ item.lbkey }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6"> </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase btn-default py-2 px-6 me-2 bg-gradient-primary"
        color="#fff"
        small
        @click="submit_diff(true)"
        >ADD</v-btn
      >
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase btn-default py-2 px-6 me-2 bg-gradient-primary"
        color="#fff"
        small
        @click="submit_diff(false)"
        >Delete</v-btn
      >
    </v-row>
  </v-container>
</template>
<script>
import FileCommon from "@/views/Components/FileCommon.vue";
import { mapGetters } from "vuex";

export default {
  name: "LBKEY-Tools",
  data() {
    return {
      source: null,
      source_data: [],
      source_slice: [],
      dest: null,
      dest_data: [],
      dest_slice: [],
    };
  },
  mixins: [FileCommon],
  // mixins 要用正確的引用方式
  methods: {
    ...mapGetters(["getToken"]),
    onSourceChange(e) {
      this.createInput(e, true);
    },
    onDestFileChange(e) {
      this.createInput(e, false);
    },
    createInput(file, source) {
      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          /* handle a successful result */

          let data = [];
          var allrows = this.fileinput.split("\n");
          for (var row = 0; row < allrows.length; row++) {
            let key = allrows[row];
            if (key.includes("lbkey")) {
              continue;
            }

            data.push({ lbkey: key });
          }
          if (source == true) {
            this.source = file;
            this.source_data = data;
            this.source_slice = data.slice(0, 15);
            this.source_slice.push({ lbkey: "......" });
          } else {
            this.dest = file;
            this.dest_data = data;
            this.dest_slice = data.slice(0, 15);
            this.dest_slice.push({ lbkey: "......" });
          }
        },
        (error) => {
          /* handle an error */
          console.log(error);
        }
      );
    },
    submit_diff(add) {
      const token = this.getToken();
      const currentObj = this;
      const headers = { "Content-Type": "multipart/form-data" };
      const formData = new FormData();
      formData.append("source", this.source);
      formData.append("dest", this.dest);

      let url = null;
      let filename = null;
      if (add == true) {
        url = `${process.env.VUE_APP_SERVER_URL}/api/v1/lbkey_tool/diff-add/`;
      } else {
        url = `${process.env.VUE_APP_SERVER_URL}/api/v1/lbkey_tool/diff-remove/`;
      }

      this.axios.post(url, formData, { headers }).then(function (response) {
        currentObj.forceFileDownload(response, filename);
      });
    },
  },
};
</script>
